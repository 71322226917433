import axios from '@axios'
import crypto from 'crypto'
import { Base64 } from 'js-base64'
import CryptoJS from 'crypto-js'
import Vue from 'vue'

const vm = new Vue()

function aesEncrypt(txt) {
    const iv = crypto.randomBytes(8).toString('hex')
    const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse('82f2ceed4c503896c8a291e560bd4325'), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
    })

    const ret = [cipher.toString(), iv]

    return Base64.encode(ret)
}
const config = {
    namespace: 'penerimaan',
}
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchPenerimaan(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/auth/penerimaan/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        uploadRincianPenerimaan(ctx, queryParams) {
            console.log('queryParams', queryParams)
            const formData = new FormData()
            formData.append('file', queryParams.file)
            formData.append('id', queryParams.id)
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/auth/rincian-penerimaan', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPenerimaanRincians(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/auth/rincian-penerimaan/?penerimaan=${queryParams.id}`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPenerimaans(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/auth/penerimaan', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchWareHouses(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/auth/warehouse', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchWareHouseCommerce(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/auth/warehouse-commerce', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchSupplier(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/auth/supplier', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deletePenerimaan(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/auth/penerimaan/${id}`)
                    .then(response => {
                        resolve(response)
                        vm.$bvToast.toast(`the ${config.namespace} has been deleted`, { title: `${config.namespace} Deleted` })
                    })
                    .catch(error => reject(error))
            })
        },
        deleteRincianPenerimaan(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/auth/rincian-penerimaan/${id}`)
                    .then(response => {
                        resolve(response)
                        vm.$bvToast.toast(`the ${config.namespace} has been deleted`, { title: `${config.namespace} Deleted` })
                    })
                    .catch(error => reject(error))
            })
        },
        addPenerimaan(ctx, userData) {
            userData.warehouse = userData.warehouse.code
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/auth/penerimaan', userData)
                    .then(response => {
                        if (response.data.errors) {
                            vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), { title: `Failed to add ${config.namespace}` })
                            reject(response)
                        } else {
                            vm.$bvToast.toast(`New ${config.namespace} has been added`, { title: `${config.namespace} Added` })
                            resolve(response)
                        }
                    })
                    .catch(error => reject(error))
            })
        },
        updateUser(ctx, { id, UserData }) {
            UserData = UserData.value.data
            if (typeof UserData.password !== undefined) UserData.password = aesEncrypt(UserData.password)
            return new Promise((resolve, reject) => {
                axios
                    .patch(`/api/auth/customers/${id}`, UserData)
                    .then(response => {
                        if (response.data.errors) {
                            vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), { title: 'Failed to update' })
                            reject(response)
                        } else {
                            vm.$bvToast.toast('Update Sucessfully', { title: 'Customer Added' })
                            resolve(response)
                        }
                    })
                    .catch(error => reject(error))
            })
        },
        warehouseOnSearch(ctx, { search }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`api/auth/warehouse-commerce?sort_by=created_at&direction=desc&q=${search}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        productOnSearch(ctx, { search }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`api/auth/produk?sort_by=nama_produk&direction=asc&q=${search}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        retrieveReceive(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`api/auth/receive/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        importExcel(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/auth/receive-details/import-excel', payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
