var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('side-form',{attrs:{"is-add-new-page-sidebar-active":_vm.isShowFormAdd,"is-loading":_vm.isLoading,"type-form":_vm.typeForm,"data":_vm.warehouseData},on:{"update:isAddNewPageSidebarActive":function($event){_vm.isShowFormAdd=$event},"update:is-add-new-page-sidebar-active":function($event){_vm.isShowFormAdd=$event},"onSubmit":_vm.onSubmit}}),_c('b-overlay',{attrs:{"id":"overlay-background","spinner-variant":"primary","variant":"transparent","opacity":"1.00","blur":"0.5em","rounded":"sm","show":_vm.isLoading,"import-excel":""}},[_c('b-card',{staticClass:"knowledge-base-bg text-center",style:({
      backgroundImage: ("url(" + (require('@/assets/images/banner/banner.png')) + ")"),
      backgroundAttachment: 'fixed',
    }),attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"card-body"},[_c('h2',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.receiveData ? _vm.receiveData.document_number : '')+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Rincian Penerimaan ")])],1)],1)],1),_c('b-card',[_c('own-table',{ref:"receiveDetailTable",attrs:{"name-table":"receive-details","table-columns":_vm.columns,"use-custom-filter":true,"custom-query-filter":_vm.queryFilter,"export-excel":false,"import-excel":"","extend-query":("&parent_id=" + (_vm.$route.params.id))},on:{"searchFieldOnChange":_vm.searchOnChange,"evt-add":_vm.showAddForm},scopedSlots:_vm._u([{key:"import-excel-template",fn:function(){return [_c('b-button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.importExcel()}}},[_vm._v(" Import Excel ")])]},proxy:true},{key:"cell(no)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.data.index + 1))])]}},{key:"cell(document_number)",fn:function(ref){
    var data = ref.data;
return [_c('b-link',{attrs:{"to":{ name: 'apps-penerimaan-view', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.value))])]}},{key:"cell(created_at)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(data.data.item.created_at)))])]}},{key:"cell(is_default)",fn:function(ref){
    var data = ref.data;
return [_c('b-badge',{attrs:{"pill":"","variant":data.item.is_default ? 'success' : 'danger'}},[_c('feather-icon',{attrs:{"icon":data.item.is_default ? 'CheckIcon' : 'XIcon'}})],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"p-0","menu-class":"position-absolute","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.edit(data.data.item)}}},[_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.onDelete(data.data.item.id)}}},[_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])])],1)],1)]}}])})],1),_c('b-modal',{attrs:{"ok-only":""},on:{"ok":_vm.okImportHandler},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
    var ok = ref.ok;
return [_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return ok()}}},[_c('span',[_vm._v("Upload")]),(_vm.isLoadingImport)?_c('b-spinner',{staticClass:"ml-1",attrs:{"small":""}}):_vm._e()],1)]}}]),model:{value:(_vm.showModalImport),callback:function ($$v) {_vm.showModalImport=$$v},expression:"showModalImport"}},[_c('span',[_vm._v("Use GT Excel Template From Ascend to do import")]),_c('b-form-file',{attrs:{"state":Boolean(_vm.excelFile),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.excelFile),callback:function ($$v) {_vm.excelFile=$$v},expression:"excelFile"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }