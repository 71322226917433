<template>
  <div>
    <side-form :is-add-new-page-sidebar-active.sync="isShowFormAdd" :is-loading="isLoading" :type-form="typeForm"
      :data="warehouseData" @onSubmit="onSubmit" />
    <b-overlay id="overlay-background" spinner-variant="primary" variant="transparent" opacity="1.00" blur="0.5em"
      rounded="sm" :show="isLoading" import-excel>
      <b-card no-body class="knowledge-base-bg text-center" :style="{
        backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        backgroundAttachment: 'fixed',
      }">
        <b-card-body class="card-body">
          <h2 class="text-primary">
            {{ receiveData ? receiveData.document_number : '' }}
          </h2>
          <b-card-text class="mb-2">
            Rincian Penerimaan
            <!--                        <span>Popular searches: </span>
                                                <span class="font-weight-bolder">Sales automation, Email marketing</span>-->
          </b-card-text>
          <!-- form -->
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-card>
      <own-table ref="receiveDetailTable" name-table="receive-details" :table-columns="columns" :use-custom-filter="true"
        :custom-query-filter="queryFilter" :export-excel="false" import-excel
        :extend-query="`&parent_id=${$route.params.id}`" @searchFieldOnChange="searchOnChange" @evt-add="showAddForm">
        <template #import-excel-template>
          <b-button class="btn btn-warning" @click="importExcel()">
            Import Excel
          </b-button>
        </template>
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(document_number)="{ data }">
          <b-link :to="{ name: 'apps-penerimaan-view', params: { id: data.item.id } }">
            {{ data.value }}</b-link>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(is_default)="{ data }">
          <b-badge pill :variant="data.item.is_default ? 'success' : 'danger'">
            <feather-icon :icon="data.item.is_default ? 'CheckIcon' : 'XIcon'" />
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" menu-class="position-absolute" no-caret
              :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="edit(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
    <b-modal v-model="showModalImport" ok-only @ok="okImportHandler">
      <span>Use GT Excel Template From Ascend to do import</span>
      <b-form-file v-model="excelFile" :state="Boolean(excelFile)" placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..." />
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="success" @click="ok()">
          <span>Upload</span>
          <b-spinner v-if="isLoadingImport" small class="ml-1" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
  BOverlay,
  BCardBody,
  BCardText,
  BButton,
  BFormFile,
  BModal,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onUnmounted, watch, onMounted } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import config from '@/config/config'
import SideForm from './components/SideFormDetail.vue'
import penerimaanStoreModule from './penerimaanStoreModule'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    SideForm,
    BLink,
    BOverlay,
    BCardBody,
    BCardText,
    BButton,
    BFormFile,
    BModal,
    BSpinner,
  },
  setup(props, ctx) {
    const RECEIVE_STORE_MODULE = 'receive'
    if (!store.hasModule(RECEIVE_STORE_MODULE)) {
      store.registerModule(RECEIVE_STORE_MODULE, penerimaanStoreModule)
    }
    onUnmounted(() => {
      store.unregisterModule(RECEIVE_STORE_MODULE)
    })
    const receiveDetailTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Kode Produk',
        key: 'kd_produk',
        sortable: true,
      },
      {
        label: 'Quantity',
        key: 'quantity',
        sortable: true,
      },
      {
        label: 'Keterangan',
        key: 'note',
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const warehouseData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      receiveDetailTable.value.submit(formData, isSuccess => {
        if (isSuccess) {
          isShowFormAdd.value = false
        }
        isLoading.value = false
      })
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      warehouseData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const edit = data => {
      typeForm.value = 'edit'
      receiveDetailTable.value.onEdit()
      warehouseData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      receiveDetailTable.value.onDelete(id)
    }

    const receiveData = ref(null)

    const retrieve = () => {
      isLoading.value = true
      store
        .dispatch('receive/retrieveReceive', {
          id: ctx.root.$route.params.id,
        })
        .then(res => {
          receiveData.value = res.data
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    onMounted(() => {
      retrieve()
    })
    // const formatDateTime = date => formatDate(date)

    const excelFile = ref(null)
    const showModalImport = ref(false)

    const isLoadingImport = ref(false)

    const importExcel = () => {
      showModalImport.value = true
    }

    const okImportHandler = evt => {
      isLoadingImport.value = true
      evt.preventDefault()
      const formData = new FormData()
      // console.log(excelFile)
      formData.append('receive_id', ctx.root.$route.params.id)
      formData.append('uploaded_file', excelFile.value)
      store
        .dispatch('receive/importExcel', formData)
        .then(res => {
          if (res.status === 200) {
            showModalImport.value = false
            receiveDetailTable.value.refetchData()
          }
        })
        .finally(() => {
          isLoadingImport.value = false
          receiveDetailTable.value.refetchData()
        })
    }

    return {
      columns,
      queryFilter,
      receiveDetailTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      edit,
      warehouseData,
      typeForm,
      onDelete,
      receiveData,

      excelFile,
      showModalImport,
      importExcel,
      isLoadingImport,
      okImportHandler,
      config,
    }
  },
}
</script>
